import React from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import "./Menu.css";
import { Home } from "./Home/Home";
import { About } from "./About/About";
import { Courses } from "./Courses/Courses";
import { Membership } from "./Membership/Membership";
import { News } from "./News/News";
import { Scoresheets } from "./Scoresheets/Scoresheets"
import Records from "./Records/Records";
import Contact from "./Contact/Contact";
import Links from "./Links/Links";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { LinkContainer } from "react-router-bootstrap";
import { Covid } from "./Announcements/Covid";
import Tournaments from "./Tournaments/Tournaments";
import { ClubDocs } from "./ClubDocs/ClubDocs";
import NotFound from "./NotFound";

const Menu: React.FC = () => {
  return (
    <Router>
      <Navbar collapseOnSelect variant="dark" expand="lg">
        <Navbar.Brand>
          <Link className="navbar-brand" to="/">
            Waterside Archers
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <LinkContainer
              exact
              className="nav-link"
              to="/about"
              activeClassName="active"
            >
              <Nav.Link>Welcome</Nav.Link>
            </LinkContainer>
            <LinkContainer
              exact
              className="nav-link"
              to="/news"
              activeClassName="active"
            >
              <Nav.Link>Latest News</Nav.Link>
            </LinkContainer>
            <LinkContainer
              className="nav-link"
              to="/beginners-courses"
              activeClassName="active"
            >
              <Nav.Link>Courses</Nav.Link>
            </LinkContainer>
            <LinkContainer
              className="nav-link"
              to="/tournaments"
              activeClassName="active"
            >
              <Nav.Link>Tournaments</Nav.Link>
            </LinkContainer>

            <NavDropdown title="Club Info" id="nav-dropdown">
              <NavDropdown.Item href="/about">About the Club</NavDropdown.Item>
              <NavDropdown.Item href="/membership">Membership</NavDropdown.Item>
              <NavDropdown.Item href="/contact">Contact Us</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Resources" id="nav-dropdown">
              <NavDropdown.Item href="/club">Policies, Procedures & Docs</NavDropdown.Item>
              <NavDropdown.Item href="/records">Club Records</NavDropdown.Item>
              <NavDropdown.Item href="/scoresheets">Scoresheets</NavDropdown.Item>
              <NavDropdown.Item href="/links">Useful Links</NavDropdown.Item>
            </NavDropdown>

            {/* <LinkContainer
              to="/Covid"
              className="nav-link"
              activeClassName="active"
            >
              <Nav.Link>Covid-19</Nav.Link>
            </LinkContainer> */}
          </Nav>
          <Nav>
            <Nav.Link href="https://www.facebook.com/pg/WatersideArchers/" aria-label="Waterside Archers Facebook">
              <i
                className="fab fa-facebook-square fa-2x"
                style={{ color: "white" }}
              ></i>
            </Nav.Link>
            <Nav.Link href="https://www.instagram.com/waterside_archers/" aria-label="Waterside Archers Instagram">
              <i
                className="fab fa-instagram-square fa-2x"
                style={{ color: "white" }}
              ></i>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/about" component={About} />
        <Route path="/beginners-courses" component={Courses} />
        <Route path="/membership" component={Membership} />
        <Route path="/records" component={Records} />
        <Route path="/contact" component={Contact} />
        <Route path="/links" component={Links} />
        <Route path="/covid" component={Covid} />
        <Route path="/tournaments" component={Tournaments} />
        <Route path="/club" component={ClubDocs} />
        <Route path="/news" component={News} />
        <Route path="/scoresheets" component={Scoresheets} />
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default Menu;
