import React from "react";
import Header from "../Common/Header";
import { ScoresheetsTable } from "./ScoresheetsTable";
import { Helmet } from "react-helmet";

export const Scoresheets: React.FC = () => {

  return (
    <div className="scoresheets">

      <Helmet>
        <title>Waterside Archers - Scoresheets</title>
        <meta name="description" content="Downloadable archery scoresheets from Waterside Archers." />
      </Helmet>
      <Header text="Scoresheets" />
      
      <div className="container">
        <p className="lead">
           Scoresheets to download and print for use at the range can be found below.
        </p>       

        <div className="row pt-5">
          <div className="col-md-3">
            <div className="container">
            <ScoresheetsTable id="outdoorImperialScores" title="Outdoor Imperial" />
            </div>
          </div>
          <div className="col-md-3">
            <div className="container">
            <ScoresheetsTable id="outdoorMetricScores" title="Outdoor Metric" />
            </div>
          </div>
          <div className="col-md-3">
            <div className="container">
            <ScoresheetsTable id="indoorScores" title="Indoor" />
            </div>
          </div>
          <div className="col-md-3">
            <div className="container">
            <ScoresheetsTable id="cloutAndWandScores" title="Clout & Wand" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
